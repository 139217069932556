import * as React from "react"
import { Accordion } from "react-bootstrap"

const Files = props => {
  return (
    <div className="col-lg-12 mb-3" id="files_panel">
      <Accordion>
        <Accordion.Item eventKey={0} className="panel panel-beige">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-paperclip" />
            </span>
            <span className="panel-heading-title">Files</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-12">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <button type="button" className="btn btn-secondary btnUpload">
                        <i className="bi bi-cloud-arrow-up-fill" />{" "}
                        <span className="d-none d-md-inline">Upload</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="drive-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#drive"
                        role="tab"
                        aria-controls="drive"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Drive">
                          <i className="bi bi-database-fill" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="small-icon-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#small-icon"
                        role="tab"
                        aria-controls="small-icon"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Small Icons">
                          <i className="bi bi-ui-checks-grid" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="large-icon-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#large-icon"
                        role="tab"
                        aria-controls="large-icon"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Large Icons">
                          <i className="bi bi-stop-fill" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="View Templates"
                      >
                        <i className="bi bi-journals" />
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Trash"
                      >
                        <i className="bi bi-trash-fill" />
                      </a>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                    <li className="nav-item">
                      <button type="button" className="btn btn-secondary btnUpload me-1">
                        <i className="bi bi-cloud-arrow-up-fill" />
                      </button>
                    </li>
                    <li className="nav-item">
                      <div className="btn-group btnDriveView" role="group">
                        <button
                          id="btnGroupDrop3"
                          type="button"
                          className="btn btn-default dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-database-fill" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                          <li>
                            <a
                              className="dropdown-item"
                              id="drive-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#drive"
                              role="tab"
                              aria-controls="drive"
                              aria-selected="false"
                            >
                              <i className="bi bi-database-fill" /> Drive
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              id="small-icon-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#small-icon"
                              role="tab"
                              aria-controls="small-icon"
                              aria-selected="false"
                            >
                              <i className="bi bi-ui-checks-grid" /> Small Icons
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              id="large-icon-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#large-icon"
                              role="tab"
                              aria-controls="large-icon"
                              aria-selected="false"
                            >
                              <i className="bi bi-stop-fill" /> Large Icons
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-journals" /> Templates
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-trash-fill" /> Trash
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="tab-content autoAdjustSearchBox" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="drive"
                    role="tabpanel"
                    aria-labelledby="drive-tab"
                  >
                    <table id="tblDrive" className="table dataTable" cellSpacing="0" width="100%">
                      <thead>
                        <tr>
                          <th width="50">&nbsp;</th>
                          <th width="60%">
                            Name/Label{" "}
                            <button
                              type="button"
                              className="btn btn-sm btnTHSoryByTags float-end me-3"
                              data-bs-toggle="modal"
                              data-bs-target="#ContactsSortByTagModal"
                              title="Advanced Tag Search"
                            >
                              <span data-bs-toggle="tooltip" title="Advanced Tag Search">
                                <i className="bi bi-tag-fill" />
                              </span>
                            </button>
                          </th>
                          <th width="18%" className="d-none d-md-table-cell">
                            Last Activity
                          </th>
                          <th width="7%" className="text-end d-none d-lg-table-cell"></th>
                        </tr>
                        <tr className="d-none table_action_container">
                          <td colSpan="4" className="text-left">
                            <div className="batch-action-container">
                              <button className="btn btn-trash my-1">
                                <i className="bi bi-trash-fill" /> Move to Trash
                              </button>
                              <button
                                className="btn btn-default my-1"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Archive (Searchable but Hidden)"
                              >
                                <i className="bi bi-file-earmark-zip-fill" /> Archive
                              </button>
                              <button className="btn btn-secondary my-1">
                                <i className="bi bi-tag-fills" /> Apply Tag(s)
                              </button>
                              <button className="btn btn-success my-1">
                                <i className="bi bi-person-plus-fill" /> Manage Sharing
                              </button>
                              <button className="btn btn-primary my-1">
                                <i className="bi bi-unlock-fill" /> Manage Permissions
                              </button>
                              <button className="btn btn-secondary my-1">
                                <i className="bi bi-arrows-move" /> Move To...
                              </button>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody />
                    </table>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="small-icon"
                    role="tabpanel"
                    aria-labelledby="small-icon-tab"
                  >
                    <ul className="smallIconList" />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="large-icon"
                    role="tabpanel"
                    aria-labelledby="large-icon-tab"
                  >
                    <ul className="largeIconList" />
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default Files
