import * as React from "react"

const GroupProgress = props => {
  return (
    <div className="row g-0">
      <div className="col p-3 position-relative">
        <div className="progress mt-2 campaignProgress">
          <div
            className="progress-bar CP_not_started"
            role="progressbar"
            aria-label="Not started"
            style={{ width: "50%" }}
            aria-valuenow="3545"
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            className="progress-bar bg-warning CP_started"
            role="progressbar"
            aria-label="Started"
            style={{ width: "30%" }}
            aria-valuenow="56"
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            className="progress-bar bg-success CP_completed"
            role="progressbar"
            aria-label="Completed"
            style={{ width: "20%" }}
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            className="progress-bar CP_missing_merge_tags"
            role="progressbar"
            aria-label="Missing merge-tags"
            style={{ width: "20%" }}
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            className="progress-bar bg-danger CP_invalid_emails"
            role="progressbar"
            aria-label="Invalid emails"
            style={{ width: "0%" }}
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            className="progress-bar CP_unsubscribed"
            role="progressbar"
            aria-label="Unsubscribed"
            style={{ width: "20%" }}
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div
            className="progress-bar CP_do_not_contact"
            role="progressbar"
            aria-label="Do not contact"
            style={{ width: "20%" }}
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div className="CG_CTA_Container mt-2">
          <ul>
            <li className="CP_text_not_started">
              <i className="bi bi-hourglass-split" /> Not started: 3545
            </li>
            <li className="text-warning">
              <i className="bi bi-clock" /> Started: 56
            </li>
            <li className="text-success">
              <i className="bi bi-check2" /> Completed: 90
            </li>
            <li className="text-yellow">
              <i className="bi bi-code-slash" /> Missing merge-tags: 0
            </li>
            <li className="text-danger">
              <i className="bi bi-x-circle" /> Invalid emails: 0
            </li>
            <li className="CP_text_soft_bounce">
              <i className="bi bi-exclamation-triangle-fill" /> Soft bounce: 0
            </li>
            <li className="text-danger">
              <i className="bi bi-x-lg" /> Hard bounce: 0
            </li>
            <li className="text-dark">
              <i className="bi bi-clock" /> Unsubscribed: 0
            </li>
            <li className="text-black-50">
              <i className="bi bi-x-lg" /> Do not contact: 0
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GroupProgress
