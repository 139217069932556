export const HISTORY_TYPE = "history"
export const HISTORY_ICON = "bi bi-arrow-counterclockwise"
export const historyOptionLabel = () => "History"

export const PRIVATE_NOTE_TYPE = "private_note"
export const PRIVATE_NOTE_ICON = "bi bi-sticky-fill"
export const privateNoteLabel = () => "Add Note"

export const SEQUENCE_TYPE = "sequence"
export const SEQUENCE_ICON = "bi bi-three-dots"
export const sequenceLabel = () => "Sequence(s) Add/Remove"
export const sequenceLabelForHistory = () => "Add/Remove Sequence(s)"

export const CHAT_TO_TYPE = "chatTo"
export const CHAT_TO_ICON = "bi bi-chat-dots-fill"
export const chatToLabel = name => {
  if (name) {
    return "Chat to " + name
  }

  return "Chat to"
}

export const EMAIL_TYPE = "email"
export const EMAIL_ICON = "bi bi-envelope-fill"
export const emailLabel = email => {
  if (email) {
    return "Email to " + email
  }

  return "Email to"
}

export const SMS_TYPE = "SMS"
export const SMS_ICON = "bi bi-phone"
export const smsLabel = email => {
  if (email) {
    return "SMS to " + email
  }

  return "SMS to"
}

export const PHONE_TYPE = "phone"
export const PHONE_ICON = "bi bi-telephone-fill"
export const phoneLabel = email => {
  if (email) {
    return "Call " + email
  }

  return "Call"
}

export const VIDEO_RECORDING_TYPE = "videoRecording"
export const VIDEO_RECORDING_ICON = "bi bi-camera-video-fill"
export const videoRecordingLabel = () => "Video call / Video Meeting"

export const VIDEO_CALL_TYPE = "videocall"
export const VIDEO_CALL_ICON = "bi bi-camera-video-fill"
export const videoCallLabel = () => "Video call / Video Meeting"

export const MAP_TYPE = "map"
export const MAP_ICON = "bi bi-geo-alt-fill"
export const mapLabel = address => {
  if (address) {
    return "Directions to " + address
  }

  return "Directions to"
}

export const POSTAL_MAIL_TYPE = "postalMail"
export const POSTAL_MAIL_ICON = "bi bi-box-seam-fill"
export const postalMailLabel = address => {
  if (address) {
    return "Mail to " + address
  }

  return "Mail to"
}
