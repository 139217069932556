import * as React from "react"
import { useRef, useState } from "react"
import { Link } from "gatsby"

import ContactTable from "../../Listing/contactTable"
import { onGrabData } from "../../Listing/contactListHelper"
import { Accordion } from "react-bootstrap"
import clsx from "clsx"
import ContactLazyLoad from "../../Listing/contactLazyLoad"

const Members = ({ groupId, group }) => {
  const [membersList, setMembersList] = useState(true)
  const triggerRef = useRef(null)
  const [deleteContactRow, setDeleteContactRow] = useState(null)
  const [isEscalateContact, setIsEscalateContact] = useState(false)
  const updateMembersList = () => {
    setMembersList(!membersList)
  }
  const [searchTerm, setSearchTerm] = useState("")

  const { data, loading } = ContactLazyLoad({
    triggerRef,
    onGrabData,
    deleteContactRow,
    setDeleteContactRow,
    groupId,
    isEscalateContact,
    searchTerm,
    setSearchTerm,
  })

  return (
    <div className="col-lg-12 mb-3" id="team_panel">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="panel panel-green">
          <Accordion.Header onClick={updateMembersList}>
            <span className="panel-heading-icon">
              <i className="bi bi-person-fill" />
            </span>
            <span className="panel-heading-title">{group.name} Members</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-12 mb-1">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown m-0" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" />{" "}
                          <span className="d-none d-md-inline">Add to Group</span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <li>
                            <Link to={`/contacts/add?groupId=${groupId}`} className="dropdown-item" href="#">
                              <i className="bi bi-person-plus-fill" /> Contact
                            </Link>
                          </li>
                          <li>
                            <Link to={`/csv-import?groupId=${groupId}`} className="dropdown-item">
                              <i className="bi bi-table" /> Upload CSV
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                {membersList && (
                  <ContactTable
                    data={data}
                    groupId={groupId}
                    setDeleteContactRow={setDeleteContactRow}
                    setIsEscalateContact={setIsEscalateContact}
                  />
                )}
                <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default Members
