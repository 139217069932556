import * as React from "react"
import Form from "react-bootstrap/Form"
import Moment from "react-moment"
import { useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { GROUP_HISTORY_TYPES, GROUP_HISTORY_TYPE_HISTORY } from "./historyNoteHelper"
import SendEMail from "./sendEmail"
import { TYPE_CHAT_TO, TYPE_EMAIL, TYPE_HISTORY } from "./historyNoteHelper"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { sidebarLoaderHide, sidebarLoaderShow } from "../../../../../redux/reducers/sidebar/loader"
import { getFullName, getProfileImage } from "../../../../../services/settingsHelper"
import AxiosInstance from "../../../../../services/axiosInstance"
import { EMAIL_TYPE } from "../../../Detail/HistoryNote/historyNoteHelper"

const HistoryNote = ({
  group,
  historyBlock,
  setHistoryBlock,
  historyNotes,
  setHistoryNotes,
  sideBarLoader,
  dispatch,
}) => {
  const headerContainerHeight = 5,
    [closeHistoryBlock, setCloseHistoryBlock] = useState(false),
    [historyType, setHistoryType] = useState("private_note"),
    [chatBlockHeight, setChatBlockHeight] = useState(window.innerHeight),
    [chatBlockTop, setChatBlockTop] = useState(headerContainerHeight),
    [historyBlockHeight, setHistoryBlockHeight] = useState(window.innerHeight),
    [updateHistoryBlock, setUpdateHistoryBlock] = useState(false),
    [historyBlockName, setUpdateHistoryName] = useState(GROUP_HISTORY_TYPE_HISTORY.label),
    [historyBlockIcon, setUpdateHistoryIcon] = useState(GROUP_HISTORY_TYPE_HISTORY.icon),
    [notesTextArea, setNotesTextArea] = useState(""),
    [notesTextAreaError, setNotesTextAreaError] = useState(""),
    [addNoteArea, setAddNoteArea] = useState(true),
    [sendEmail, setSendEmail] = useState(false),
    [generateReport, setGenerateReport] = useState(false),
    [userEMails, setUserEMails] = useState([]),
    [emailBodyData, setEmailBodyData] = useState("")

  let profileImage = getProfileImage()
  if (!profileImage) {
    profileImage = "StaticImages/avatar.gif"
  }

  const addNotes = () => {
    if (notesTextArea && group.id) {
      dispatch(sidebarLoaderShow())
      setNotesTextAreaError("")

      return new Promise(async () => {
        await AxiosInstance.post(`/history-note/add`, {
          groupId: group.id,
          content: notesTextArea,
          mode: "public",
          type: "note",
          status: true,
        })
          .then(function (response) {
            if (response.status === 201) {
              setUpdateHistoryBlock(true)
              setNotesTextArea("")
              dispatch(sidebarLoaderHide())
            }
          })
          .catch(function (error) {
            dispatch(sidebarLoaderHide())
            if (error.response.status === 400) {
              setNotesTextAreaError(error.response.data.message)
            } else {
              setNotesTextAreaError(error.response.data.detail)
            }
          })
      })
    }
  }

  const getHistoryNotes = () => {
    return new Promise(async () => {
      await AxiosInstance.get(`/contact/group/history-note/${group.id}`)
        .then(function (response) {
          setHistoryNotes(response.data)
          return true
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setNotesTextAreaError(error.response.data.message)
          } else {
            setNotesTextAreaError(error.response.data.detail)
          }
        })
    })
  }

  const getUserEmails = () => {
    return new Promise(async () => {
      await AxiosInstance.get(`/settings/user/emails`)
        .then(function (response) {
          if (response.data.emails.length > 0) {
            setUserEMails(response.data.emails)
          } else {
            setUserEMails(["noreply@SwiftCRM.com", "Connect Email Sender"])
          }
          return true
        })
        .catch(function (error) {
          setUserEMails([])
        })
    })
  }

  const updateHistoryBlockHeight = () => {
    if (
      document &&
      document.querySelector(".ChatDockHeader") &&
      document.querySelector(".ChatDockFooterContainer")
    ) {
      setChatBlockHeight(window.innerHeight - headerContainerHeight)
      setChatBlockTop(headerContainerHeight)
      setHistoryBlockHeight(
        window.innerHeight -
          headerContainerHeight -
          document.querySelector(".ChatDockHeader").clientHeight -
          document.querySelector(".ChatDockFooterContainer").clientHeight
      )
    }
  }

  const showAddNote = () => {
    setAddNoteArea(true)
    setGenerateReport(false)
    setSendEmail(false)
  }

  const showGenerateReport = () => {
    setAddNoteArea(false)
    setGenerateReport(true)
    setSendEmail(false)
  }

  const showEmailBlock = () => {
    setAddNoteArea(false)
    setGenerateReport(false)
    setSendEmail(true)
  }

  const toggleHistoryBlock = type => {
    if (type === "report") {
      showGenerateReport()
    } else if (type === "email") {
      showEmailBlock()
    } else {
      showAddNote()
    }
  }

  const handleGroupHistoryChat = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const historyType = queryParams.get("historyType")
    const previewUrl = queryParams.get("previewUrl")

    if (historyType === EMAIL_TYPE) {
      let emailData = GROUP_HISTORY_TYPES.find(item => item.type === TYPE_EMAIL)
      setUpdateHistoryName(emailData.label)
      setUpdateHistoryIcon(emailData.icon)
      setEmailBodyData(previewUrl)
      toggleHistoryBlock(TYPE_EMAIL)
    }

    // if (historyType === SMS_TYPE) {
    // }
    // setDefaultMessage(previewUrl)
  }

  useEffect(() => {
    getUserEmails()
  }, [])

  useEffect(() => {
    if (updateHistoryBlock) {
      setUpdateHistoryBlock(false)
      if (group.id) {
        getHistoryNotes()
      }
    }

    let updateHistoryBlockHeightInterval = setInterval(() => {
      updateHistoryBlockHeight()
      clearInterval(updateHistoryBlockHeightInterval)
    }, 500)

    if (closeHistoryBlock) {
      setCloseHistoryBlock(false)
      setHistoryBlock(false)
    }
  })

  useEffect(() => {
    handleGroupHistoryChat()
  }, [])

  return (
    <div className="col-lg-6">
      <div className="chatDockSwitch">
        <input type="checkbox" id="chatDockSwitch" />
        <label htmlFor="chatDockSwitch" className="checkmark" />
      </div>
      <div className="SCChatDockNonSocialAssetOnly">
        <div className="ChatDockHeader">
          <div className="user_info">
            <span className="ChatDockHeaderAssetIcon">
              <i className="bi bi-people-fill" />
            </span>
            {group.name}
          </div>
          <div className="ChatDockInputOptContainer">
            <div className="dropdown ChatDockInputOpt">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  <i className={historyBlockIcon} />
                  &nbsp;{historyBlockName}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ maxHeight: "250px", overflow: "auto" }}>
                  {GROUP_HISTORY_TYPES.map((fieldOption, index) => {
                    // START : skipping contact entity fields if they are available;

                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={e => {
                          toggleHistoryBlock(fieldOption.type)
                          setUpdateHistoryName(fieldOption.label)
                          setUpdateHistoryIcon(fieldOption.icon)
                        }}
                        disabled={fieldOption.type === ""}
                        // style={style}
                      >
                        {fieldOption.type !== "" && (
                          <React.Fragment>
                            <i className={fieldOption.icon} />
                            &nbsp;&nbsp;
                          </React.Fragment>
                        )}
                        {fieldOption.type === TYPE_EMAIL ? "Email To " + group.name : fieldOption.label}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <a
            className="btnCloseChatDock"
            data-bs-toggle="tooltip"
            data-placement="left"
            data-animation="false"
            title="Close"
            onClick={() => {
              setCloseHistoryBlock(true)
            }}
          >
            <i className="bi bi-x-lg" />
          </a>
        </div>

        <div
          className="ChatDockHistoryContainer"
          style={{
            position: "relative",
            top: "0",
            left: "0",
            overflow: "scroll",
            height: historyBlockHeight,
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <ul className="chat">
            {historyNotes !== null &&
              historyNotes.length > 0 &&
              historyNotes.map((historyData, historyIndex) => {
                let chatClass = historyData.messageSent ? "self" : "other"
                // let imagePath = historyData.messageSent ? "swift_cloud_flag.jpg" : "roger.jpg"
                let contactName = "swiftcloud"
                return (
                  <React.Fragment key={historyIndex}>
                    {(historyData.content || historyData.title) && (
                      <li className={chatClass} key={historyIndex}>
                        <div className="chat-msg">
                          <div className="avatar">
                            <a
                              href="#"
                              className="quickcontact_userSelect d-block"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title={getFullName()}
                            >
                              <img src={`${process.env.GATSBY_WRANGLER_URL}${profileImage}`} alt="" />
                            </a>
                          </div>
                          <div className="msg">
                            {historyData.title && (
                              <p className="subjectLine">
                                <strong>{historyData.title}</strong>
                              </p>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: historyData.content.replace(/(?:\r\n|\r|\n)/g, "<br />"),
                              }}
                            />
                          </div>
                        </div>

                        <span className="msg-by-time">
                          <Moment format="MMM D, YYYY">{historyData.updatedAt}</Moment>&nbsp;
                          <span className="chat-msg-time-detail">
                            (<Moment format="dddd">{historyData.updatedAt}</Moment>) -&nbsp;
                            {historyData.type === TYPE_EMAIL && (
                              <>
                                <i className="bi bi-envelope-fill " />
                                &nbsp; Email sent to ALL Contacts&nbsp;
                              </>
                            )}
                            {historyData.type === TYPE_CHAT_TO ||
                              (historyData.type === TYPE_HISTORY && (
                                <>
                                  <i className="bi bi-chat-dots-fill " /> Chat by&nbsp;
                                  <Link to={`contact/detail/${historyData.contactId}`}>@{contactName}</Link>
                                  &nbsp;
                                </>
                              ))}
                            at &nbsp;
                            <Moment format="h:mm a">{historyData.updatedAt}</Moment>
                          </span>
                        </span>
                      </li>
                    )}
                  </React.Fragment>
                )
              })}
          </ul>
        </div>

        <div className="ChatDockFooterContainer">
          {addNoteArea && (
            <div className="ChatDockOpt ChatDockPrivateNoteOpt">
              <div className="mb-2">
                <Form>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add Note"
                    value={notesTextArea}
                    onChange={event => {
                      setNotesTextArea(event.target.value)
                    }}
                    onMouseLeave={event => {
                      setNotesTextArea(event.target.value)
                    }}
                  />
                  {notesTextAreaError && <div className="error-text">{notesTextAreaError}</div>}
                </Form>
              </div>
              <div className="row">
                <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                  <a
                    data-bs-toggle="modal"
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Add URL"
                    aria-label="Add URL"
                  >
                    <i className="bi bi-link-45deg" />
                  </a>
                  <a
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Insert Image/Video"
                    aria-label="Insert Image/Video"
                  >
                    <i className="bi bi-camera-fill" />
                  </a>
                  <a
                    className="chatNoteImg"
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Upload file"
                    aria-label="Upload file"
                  >
                    <i className="bi bi-paperclip" />
                  </a>
                  <a
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Inject Emoji"
                    aria-label="Inject Emoji"
                  >
                    <i className="bi bi-emoji-smile" />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    className="chatNoteImgFile"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </div>
                <div className="col-lg-6 text-end">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addNotes}
                    disabled={sideBarLoader}
                  >
                    {sideBarLoader && (
                      <>
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>{" "}
                        Sending...
                      </>
                    )}
                    {!sideBarLoader && (
                      <>
                        <i className="bi bi-check2" />
                        Add Note
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          {generateReport && (
            <div className="ChatDockOpt ChatDockReportOpt">
              <div className="text-center py-3">
                <button type="button" className="btn btn-primary">
                  <i className="bi bi-bar-chart-line-fill" />
                  Generate Report
                </button>
              </div>
            </div>
          )}

          {sendEmail && (
            <SendEMail
              groupId={group.id}
              setUpdateHistoryBlock={setUpdateHistoryBlock}
              userEMails={userEMails}
              emailBodyData={emailBodyData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(state => {
  let sideBarLoader = 0
  if (state.sidebarLoaderReducer) {
    sideBarLoader = state.sidebarLoaderReducer.sideBarLoader
  }

  return {
    sideBarLoader: sideBarLoader,
  }
})(HistoryNote)
