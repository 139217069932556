export const TYPE_HISTORY = "history"
export const TYPE_PRIVATE_NOTE = "private_note"
export const TYPE_CHAT_TO = "chatTo"
export const TYPE_REPORT = "report"
export const TYPE_EMAIL = "email"

export const GROUP_HISTORY_TYPE_HISTORY = {
  type: TYPE_HISTORY,
  label: "History",
  icon: "bi bi-arrow-counterclockwise",
}

export const GROUP_HISTORY_TYPES = [
  { ...GROUP_HISTORY_TYPE_HISTORY },
  {
    type: TYPE_PRIVATE_NOTE,
    label: "Add Note",
    icon: "bi bi-sticky-fill",
  },
  {
    type: TYPE_REPORT,
    label: "Generate Report",
    icon: "bi bi-graph-up",
  },
  {
    type: TYPE_EMAIL,
    label: "Email To All Contacts",
    icon: "bi bi-envelope",
  },
]

export const GROUP_EMAIL_SHORTCODES = [
  {
    label: "First Name",
    code: "[name_first]",
  },
  {
    label: "Both Name",
    code: "[name_both]",
  },
  {
    label: "Company Name",
    code: "[company]",
  },
]
