import * as React from "react"
import Tags from "../../Common/tags"
import {
  ALL_PERMISSIONS,
  ALL_PRIVACY,
  ALL_ROLE_TYPES,
  DATA_IS_FOR_CONTACT,
  DATA_IS_FOR_COMPANY,
} from "../GroupHelper"
import { Accordion, Form, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useEffect, useState } from "react"
import AxiosInstance from "../../../../services/axiosInstance"
import SEODescription from "../../../Drive/Doc/MoreOptions/seoDescription"

const MorePanel = ({
  entityId,
  api,
  group,
  tags,
  setTags,
  tempTags,
  setTempTags,
  groupPermission,
  groupPrivacy,
  groupRole,
  groupRoleType,
  setGroupPrivacy,
  setGroupPermission,
  seoDescription,
}) => {
  const [tempRoleType, setTempRoleType] = useState(groupRoleType),
    [tempRole, setTempRole] = useState(groupRole),
    [roles, setRoles] = useState([]),
    [defaultPrivacyValue, setDefaultPrivacyValue] = useState(groupPrivacy),
    [defaultPrivacyLabel, setDefaultPrivacyLabel] = useState(""),
    [defaultPrivacyIcon, setDefaultPrivacyIcon] = useState(""),
    [defaultPermissionValue, setDefaultPermissionValue] = useState(groupPermission),
    [defaultPermissionLabel, setDefaultPermissionLabel] = useState(""),
    [defaultPermissionIcon, setDefaultPermissionIcon] = useState("")

  const businessRoles = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        setRoles(response.data.items)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    businessRoles()

    const defaultPrivacy = ALL_PRIVACY.find(privacyData => privacyData.value === defaultPrivacyValue)
    setDefaultPrivacyLabel(defaultPrivacy.label)
    setDefaultPrivacyIcon(defaultPrivacy.icon)

    const defaultPermission = ALL_PERMISSIONS.find(
      permissionData => permissionData.value === defaultPermissionValue
    )
    setDefaultPermissionLabel(defaultPermission.label)
    setDefaultPermissionIcon(defaultPermission.icon)
  }, [])

  return (
    <div className="col-lg-12 mb-3" id="more_panel">
      <Accordion>
        <Accordion.Item eventKey={0} className="panel panel-grey">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-three-dots" />
            </span>
            <span className="panel-heading-title">More Options</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-lock-fill" /> Privacy
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <Dropdown className="groupPrivacy">
                        <OverlayTrigger placement="top" overlay={<Tooltip>{defaultPrivacyLabel}</Tooltip>}>
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="btn-default overflow-hidden mw-100"
                          >
                            <>
                              <i className={defaultPrivacyIcon} /> {defaultPrivacyLabel}
                            </>
                          </Dropdown.Toggle>
                        </OverlayTrigger>
                        <Dropdown.Menu>
                          {ALL_PRIVACY.map((AllPrivacy, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                value={AllPrivacy.value}
                                href="#"
                                onClick={() => {
                                  setDefaultPrivacyValue(AllPrivacy.value)
                                  setDefaultPrivacyLabel(AllPrivacy.label)
                                  setDefaultPrivacyIcon(AllPrivacy.icon)
                                  setGroupPrivacy(AllPrivacy.value)
                                }}
                              >
                                <i className={AllPrivacy.icon} /> {AllPrivacy.label}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-gear-fill" /> Permissions
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <Dropdown className="groupPermissions">
                        <OverlayTrigger placement="top" overlay={<Tooltip>{defaultPermissionLabel}</Tooltip>}>
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="btn-default overflow-hidden mw-100"
                          >
                            <>
                              <i className={defaultPermissionIcon} /> {defaultPermissionLabel}
                            </>
                          </Dropdown.Toggle>
                        </OverlayTrigger>
                        <Dropdown.Menu>
                          {ALL_PERMISSIONS.map((AllPermission, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                value={AllPermission.value}
                                href="#"
                                onClick={() => {
                                  setDefaultPermissionValue(AllPermission.value)
                                  setDefaultPermissionLabel(AllPermission.label)
                                  setDefaultPermissionIcon(AllPermission.icon)
                                  setGroupPermission(AllPermission.value)
                                }}
                              >
                                <i className={AllPermission.icon} /> {AllPermission.label}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-person-circle"></i> This data is..
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      {group.dataIsFor === DATA_IS_FOR_CONTACT && (
                        <React.Fragment>
                          "Cold" contacts. I cannot prove Opt-in.
                          <a href="http://swiftcrm.com/support/email" target="_blank">
                            <i className="bi bi-question-circle-fill"></i>
                          </a>
                        </React.Fragment>
                      )}
                      {group.dataIsFor === DATA_IS_FOR_COMPANY && (
                        <React.Fragment>
                          My company newsletter. I can prove opt in.
                          <a href="http://swiftcrm.com/support/email" target="_blank">
                            <i className="bi bi-question-circle-fill"></i>
                          </a>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-person-circle"></i> Roles
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      {ALL_ROLE_TYPES.map((roleType, index) => {
                        let roleTypeOptionId = `group_role_type_${roleType.value}`
                        return (
                          <div className="mb-1" key={roleTypeOptionId}>
                            <Form.Control
                              type="radio"
                              name="groupRoleType"
                              id={roleTypeOptionId}
                              className="css-checkbox"
                              value={roleType.value}
                              checked={roleType.value === tempRoleType ? "checked" : ""}
                              onClick={e => {
                                setTempRoleType(roleType.value)
                              }}
                              onChange={() => {}}
                            />
                            <label
                              htmlFor={roleTypeOptionId}
                              className="css-radio-label"
                              dangerouslySetInnerHTML={{ __html: roleType.label }}
                            />

                            {roleType.options && (
                              <div>
                                <select
                                  className="form-select"
                                  name="role"
                                  onChange={e => setTempRole(e.target.value)}
                                  value={tempRole}
                                >
                                  <option>== Select Role ==</option>
                                  {roles.length > 0 &&
                                    roles.map(role => {
                                      return (
                                        <option value={role.id} key={role.id}>
                                          {role.role}
                                        </option>
                                      )
                                    })}
                                </select>
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-tags-fill"></i> Tags
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <Tags
                        entityId={entityId}
                        api={api}
                        tags={tags}
                        setTags={setTags}
                        tempTags={tempTags}
                        setTempTags={setTempTags}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-file-image"></i> Featured Image
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="folderIcon">
                        <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`} />
                      </div>
                      <button type="button" className="btn btn-default btnDocFormIconEdit">
                        <span data-bs-toggle="tooltip" data-placement="bottom" title="Edit folder image">
                          <i className="bi bi-pencil-fill"></i>
                        </span>
                      </button>
                    </div>
                  </div>

                  <SEODescription seoDescription={seoDescription} />

                  <div className="col-lg-12">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-chat-fill"></i> Comments
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      Comments are
                      <div className="toggleSwitch">
                        <input type="checkbox" value="1" name="group_comments" id="group_comments" />
                        <label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default MorePanel
