import AxiosInstance from "../../../services/axiosInstance"
import { navigate } from "gatsby"

export const onGrabData = (currentPage, pages_count, lastOffSet, groupId, searchTerm, campaignId) => {
  return new Promise(async resolve => {
    let data = {
      items: [],
      pages_count: pages_count,
    }

    if (searchTerm) {
      currentPage = 1
      lastOffSet = 0
      pages_count = 0
    }

    const searchParams = new URLSearchParams(window.location.search)
    const searchData = searchParams.get("search")

    if (pages_count === null || currentPage <= pages_count || searchTerm) {
      data = await AxiosInstance.get(`/contact/`, {
        params: {
          page: currentPage,
          groupId: groupId,
          campaignId: campaignId,
          lastOffSet: lastOffSet,
          search: searchData ?? "",
        },
      }).then(response => {
        return response.data
      })
    }
    resolve(data, pages_count)
  })
}

export const deleteContact = (contactId, groupId, data, isEscalateContact) => {
  return new Promise(async resolve => {
    let success = false
    let apiUrl = isEscalateContact
      ? `/contact/escalate/${contactId}/${groupId}`
      : `/contact/delete/${contactId}`

    await AxiosInstance.delete(apiUrl).then(response => {
      if (response.data) {
        success = response.data.success

        if (isEscalateContact) {
          navigate(`/contacts/detail/${contactId}`)
        }
      }
    })

    resolve(data, success)
  })
}
