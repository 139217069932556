import * as React from "react"
import Layout from "../../../Layout/layout"

import "../../../../css/jquery.dataTables.min.css"
import "../../../../css/bootstrap-utilities.css"
import "../../../../css/chat-dock.css"
import "../../../../css/easyui.css"
import "./contact_group_view.css"

import Members from "./members"
import Calendar from "./calendar"
import Accounting from "./accounting"
import Files from "./files"
import MorePanel from "./morePanel"
import GroupProgress from "./groupProgress"
import { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import Tags from "../../Common/tags"
import TitleName from "../../Common/titleName"
import { Button, Form } from "react-bootstrap"
import HistoryNote from "./HistoryNote/historyNote"
import Seo from "../../../seo"
import AxiosInstance from "../../../../services/axiosInstance"

const ContactGroupDetail = ({ selectedGroupId }) => {
  const groupApi = "/contact/group",
    [group, setGroup] = useState(null),
    [groupError, setGroupError] = useState(false),
    [loadGroupDetails, setLoadGroupDetails] = useState(true),
    [metaJson, setMetaJson] = useState([]),
    [seoDescription, setSeoDescription] = useState("")

  const getGroupData = async selectedGroupId => {
    await AxiosInstance.get(`${groupApi}/detail/${selectedGroupId}`).then(response => {
      fillGroupData(response.data.group)
    })
  }

  const [groupName, setGroupName] = useState(""),
    [tempGroupName, setTempGroupName] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [groupPermission, setGroupPermission] = useState(null),
    [groupPrivacy, setGroupPrivacy] = useState(null),
    [groupRole, setGroupRole] = useState(null),
    [groupRoleType, setGroupRoleType] = useState(null),
    [historyNotes, setHistoryNotes] = useState([]),
    [historyBlock, setHistoryBlock] = useState(true)
  const fillGroupData = groupData => {
    if (groupData) {
      setGroupName(groupData.name)
      setTempGroupName(groupData.name)
      setTags(groupData.tags)
      setTempTags(groupData.tags)
      setGroupPermission(groupData.permission)
      setGroupPrivacy(groupData.privacy)
      setGroupRole(groupData.role)
      setGroupRoleType(groupData.roleType)
      setHistoryNotes(groupData.historyNotes)
      setGroup(groupData)
      setSeoDescription(groupData.metaJson[0]?.seo_description ?? "")
      setMetaJson(groupData.metaJson)
    }
  }

  const saveGroupDetails = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const seoDescriptionIndex = metaJson.findIndex(obj => obj.hasOwnProperty("seo_description"))

    if (seoDescriptionIndex !== -1) {
      metaJson[seoDescriptionIndex].seo_description = formData.get("seo_description")
    } else {
      metaJson.push({ seo_description: formData.get("seo_description") })
    }

    return new Promise(async () => {
      await AxiosInstance.post(`/contact/group/edit/${group.id}`, {
        privacy: groupPrivacy,
        permission: groupPermission,
        roleType: formData.get("groupRoleType"),
        role: formData.get("role"),
        metaJson: metaJson,
      })
        .then(function (response) {
          if (response.status === 200) {
            return false
          }
        })
        .catch(function (error) {
          // if (error.response.status === 400) {
          //   setErrorMessages(error.response.data)
          // } else if (error.response.data) {
          //   setFormError(error.response.data.detail)
          // }
        })
    })
  }

  useEffect(() => {
    if (loadGroupDetails) {
      setLoadGroupDetails(false)
      getGroupData(selectedGroupId)
    }
  })

  return (
    <Layout>
      <Seo title={groupName} />
      <div id="right-section" className="h-100">
        {group && (
          <Form onSubmit={saveGroupDetails}>
            <div className="row g-0" id="contactGroupViewTemplate">
              <div className="col" id="page-urls">
                <div className="row">
                  <div className={`col-lg-${historyBlock ? "6" : "12"} contactGroupViewLeftSection`}>
                    <div className="row g-0">
                      <div className="col-sm-12 mb-3">
                        <div className="pageTitle_Editor">
                          <Link to={"/contacts/groups"} className="goBack">
                            <i className="bi bi-arrow-90deg-left" />
                          </Link>
                          <TitleName
                            entityId={group.id}
                            api={groupApi}
                            name={groupName}
                            setName={setGroupName}
                            tempName={tempGroupName}
                            setTempName={setTempGroupName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col-lg-12 mb-3">
                        <div className="row g-0">
                          <Members groupId={group.id} group={group} />

                          {/* <Calendar /> */}

                          {/* <Accounting /> */}

                          <Files />

                          <MorePanel
                            entityId={group.id}
                            api={groupApi}
                            group={group}
                            tags={tags}
                            setTags={setTags}
                            tempTags={tempTags}
                            setTempTags={setTempTags}
                            groupPermission={groupPermission}
                            groupPrivacy={groupPrivacy}
                            groupRoleType={groupRoleType}
                            groupRole={groupRole}
                            setGroupPrivacy={setGroupPrivacy}
                            setGroupPermission={setGroupPermission}
                            seoDescription={seoDescription}
                          />

                          <div className="col-lg-12 text-center">
                            <Button type="submit" className="btn btn-primary btn-lg">
                              <i className="bi bi-check2" /> Save Changes
                            </Button>{" "}
                            <button type="button" className="btn btn-magic btn-lg">
                              <i className="bi bi-megaphone-fill" /> Start Campaign
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-magic btn-lg"
                              onClick={() => setHistoryBlock(true)}
                            >
                              <i className="bi bi-clock-history" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {historyBlock && (
                    <HistoryNote
                      group={group}
                      historyBlock={historyBlock}
                      setHistoryBlock={setHistoryBlock}
                      historyNotes={historyNotes}
                      setHistoryNotes={setHistoryNotes}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    </Layout>
  )
}

export default ContactGroupDetail
