import * as React from "react"
import PrivateRoute from "../../../../components/privateRoute"
import ContactGroupDetail from "../../../../components/Contacts/Groups/Detail/detail"
import ReduxWrapper from "../../../../redux/reduxWrapper"

const Contact = props => {
  return <PrivateRoute component={ContactGroupDetail} location={props.location} selectedGroupId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<Contact {...props} />} />
export default WrappedPage

export const Head = () => <title>Contact Group Detail</title>
