import * as React from "react"
import { useEffect, useReducer, useCallback } from "react"
import debounce from "lodash/debounce"
import { deleteContact } from "./contactListHelper"

const INTERSECTION_THRESHOLD = 5
const LOAD_DELAY_MS = 100

const reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return {
        ...state,
        ...action.payload,
      }

    case "onGrabData":
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload.data],
        currentPage: action.payload.data.length === 0 ? state.currentPage : state.currentPage + 1,
        pages_count: action.payload.pages_count,
        lastOffSet:
          action.payload.data.length > 0
            ? action.payload.data[action.payload.data.length - 1].id
            : state.lastOffSet,
      }

    case "onSearch":
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        currentPage: 2,
        pages_count: action.payload.pages_count,
        lastOffSet:
          action.payload.data.length > 0
            ? action.payload.data[action.payload.data.length - 1].id
            : state.lastOffSet,
      }

    case "deleteContactIndex":
      let data = action.payload.stateData.filter(contact => contact.id !== action.payload.deleteContactId)

      return {
        ...state,
        data: [...data],
      }

    default:
      return state
  }
}

const ContactLazyLoad = ({
  triggerRef,
  onGrabData,
  deleteContactRow,
  setDeleteContactRow,
  groupId,
  isEscalateContact,
  searchTerm,
  setSearchTerm,
  options,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    currentPage: 1,
    pages_count: null,
    lastOffSet: 0,
    data: [],
  })

  const _handleEntry = async entry => {
    const boundingRect = entry.boundingClientRect
    const intersectionRect = entry.intersectionRect
    let deleteContactId = deleteContactRow

    if (
      (!state.loading &&
        entry.isIntersecting &&
        intersectionRect.bottom - boundingRect.bottom <= INTERSECTION_THRESHOLD &&
        !deleteContactRow) ||
      searchTerm
    ) {
      setSearchTerm(null)
      dispatch({ type: "set", payload: { loading: true } })
      const responseData = await onGrabData(
        state.currentPage,
        state.pages_count,
        state.lastOffSet,
        groupId,
        searchTerm
      )
      const data = responseData.items
      const pages_count = responseData.pages_count

      if (searchTerm) {
        dispatch({ type: "onSearch", payload: { data, pages_count } })
      } else {
        dispatch({ type: "onGrabData", payload: { data, pages_count } })
      }
    }

    if (deleteContactRow) {
      setDeleteContactRow(null)
      const responseData = await deleteContact(deleteContactId, groupId, state.data, isEscalateContact)
      let stateData = [...responseData]
      dispatch({ type: "deleteContactIndex", payload: { stateData, deleteContactId } })
    }
  }
  const handleEntry = debounce(_handleEntry, LOAD_DELAY_MS)

  const onIntersect = useCallback(
    entries => {
      handleEntry(entries[0])
    },
    [handleEntry]
  )

  useEffect(() => {
    if (triggerRef.current) {
      const container = triggerRef.current
      const observer = new IntersectionObserver(onIntersect, options)

      observer.observe(container)

      return () => {
        observer.disconnect()
      }
    }
  }, [triggerRef, onIntersect, options])

  return state
}

export default ContactLazyLoad
